import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import ReactMarkdown from "react-markdown";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useFilteredByLocale } from "../hooks";

interface AboutUs {
  body: string;
  title: string;
  locale: string;
}

export default function FestivalScreenings({ location }: { location: Location }) {
  const {
    allStrapiAboutUs: { edges },
  } = useStaticQuery<{ allStrapiAboutUs: EdgesQuery<{ node: AboutUs }> }>(query);

  const { body, title }: AboutUs = useFilteredByLocale(edges.map(({ node }) => node))[0];

  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title="About us" />
      <div className="flex items-center justify-center min-h-screen text-white">
        <div>
          <h1 className="mb-8">{title}</h1>
          <ReactMarkdown className="text-center">{body}</ReactMarkdown>
        </div>
      </div>
    </Layout>
  );
}

const query = graphql`
  query AboutUsQuery {
    allStrapiAboutUs {
      edges {
        node {
          body
          title
          locale
        }
      }
    }
  }
`;
